// Api BASE URLs
// export const API_URL = "https://KaffineQueen-api.ondemandcreations.com";
//export const API_URL = "https://api-dev.alcooly.com";
const env = require('./config/env')();

console.log("env", env)
export const API_URL = env.API_URL;
export const API_VERSION = "/api/v1";

// COMMON CONSTANT
export const API_TOKEN = localStorage.getItem("user_id");
export const EMAIL_REGEX = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
export const PASS_REGEX = /^(?=\S*[a-z])(?=\S*[A-Z])(?=\S*\d)(?=\S*[^\w\s])\S{8,}$/;
export const Numeric = /^[0-9]+$/;
export const ALPHABET = /^[A-Za-z]+$/;
export const ALPHANUMERIC_REGEX = /[^a-z\d]/i;
export const PASS_VALIDATION_MSG =
  "password should be at least 8 characters, one uppercase, lowercase, special character (@,-,~,_), numeric value.";

  export const PERMISSIONS_ARRAY = [
    "dashboard",
    "customers",
    "drivers",
    "brands",
    "orders",
    "categories",
    "subcategories",
    "varients",
    "products",
    "vendor",
    "heatmap",
    "promo-codes",
    "earnings",
    "push_notification",
    "content_pages",
    "faq",
    "admin_settings",
    "basic_settings",
    "installation_setting",
    "mail_settings",
    "sms_settings",
    "report",
  ];
  
  export const PERMISSIONS = PERMISSIONS_ARRAY.map((p) => {
    const name = p.replace("_", " ");
    return {
      [p]: name.charAt(0).toUpperCase() + name.slice(1),
    };
  });

// AUTH URL
export const API_LOGIN = "/admin/adminLogin";

// DASHBOARD
export const STATUS_CHANGE_API = "/admin/orderStatusChange";
export const ASSIGN_DOORDASH_API = "/admin/assignToDoordash";
export const DASHBOARD = "/adminDashboard/getDashboardList";
export const API_UPLOAD_STORE_ITEMS = "/admin/importXLS";
export const API_UPLOAD_STOREBY_ADMIN_ITEMS = "/admin/storeImportFile";

// Stores
export const API_GET_STORES_URL = "/store/";
export const API_GET_STORES_BY_ID = "/store/getstorebyId/";
// export const API_ADD_STORES = "/store/register/";
export const API_ADD_STORES = "/admin/addStoresByAdmin";
export const API_GET_STORE = "/admin/store/";
export const API_EDIT_STORES = "/admin/editStoresByAdmin";
export const API_ADD_STORE_BANKAC = "/store/addaccount/";
export const API_ADD_STORE_DOCS = "/store/addStoreDoc/";
export const API_GET_STORE_DOCS = "/store/getStoreDoc/";

// Store's Categories
export const API_GET_STORES_CATEGORIES = "/storecategory/";
export const API_GET_STORES_CATEGORIE_BY_ID = "/storecategory/edit/";
export const API_ADD_STORES_CATEGORIE = "/storecategory/add/";
export const API_EDIT_STORES_CATEGORIE = "/storecategory/update";
export const API_DELETE_STORES_CATEGORIE = "/storecategory/remove";

export const API_CATEGORY_ADD_URL = "/category/add";
export const API_CATEGORY_LIST_URL = "/category";
export const API_CATEGORY_GET_URL = "/category/addsubcategory";
export const API_CATEGORY_UPDATE_URL = "/category/updatesubcategory";
export const API_CATEGORY_DELETE_URL = "/category/remove";
export const API_GET_CATEGORY_BY_ID = "/admin/getCategory/";


export const API_GET_ALL_LOCATION = "/admin/heatMapDataByAdmin"

export const API_GET_SUBCATEGORY = "/admin/restaurantCategories/";
export const API_GET_CATEGORY_LIST = "/admin/getAllCategoryListByAdmin";
// export const API_GET_SUBCATEGORY = "/category";
export const API_GET_SUBCATEGORY_BY_ID = "/admin/getSubCategory/";
export const API_ADD_SUBCATEGORY = "/admin/addSubCategoryByAdmin";
export const API_EDIT_SUBCATEGORY = "/admin/editSubCategory";
export const API_DELETE_CATEGORY_ITEM = "/admin/removeCategory";
export const API_GET_SUBCATEGORY_LIST = "/admin/getAllSubCategoryByAdmin";
export const API_GET_CAT_LIST = "/admin/getAllCat"
export const API_GET_SUBCAT_LIST = "/admin/getSubByCat/"


export const API_GET_RESTAURANTS_URL = "/admin/storelist";
export const API_GET_RESTAURANTS_All = "/admin/restaurantlist";
export const API_RESTAURANTS_VIEW_URL = "/admin/restaurant/";
export const API_RESTAURANTS_DELETE_URL = "/admin/restaurant/remove";
export const API_RESTAURANTS_STATUS_URL = "/admin/storestatus";
export const API_GET_STORE_PRODUCT_LIST = "/restaurant/getAllStoreItem/";


export const API_RESTAURANTS_ADD_URL = "/admin/restaurant/add";
export const API_GET_RESTAURANTS_PAYMENTS = "/admin/restaurantpaymentshistory/";
export const API_RESTAURANTS_PAYMENTS = "/admin/paytorestaurant/";
// Category
export const API_GET_CATEGORY_URL = "/category/";
export const API_GET_CATEGORY = "/category/edit/";
export const API_ADD_CATEGORY = "/admin/addCategoryByAdmin";
export const API_EDIT_CATEGORY = "/admin/editCategory";
export const API_DELETE_CATEGORY = "/category/remove/";

export const API_CUISINES_LIST_URL = "/admin/cuisines";
export const API_CUISINES_ADD_URL = "/admin/cuisines/add";
export const API_CUISINES_GET_URL = "/admin/cuisines/view";
export const API_CUISINES_UPDATE_URL = "/admin/cuisines/update";
export const API_CUISINES_DELETE_URL = "/admin/cuisines/remove";

export const API_GET_RESTAURANT = "/restaurant";

export const API_PROMOTIONS_LIST_URL = "/admin/promotions";
export const API_PROMOTIONS_ADD_URL = "/admin/promotions/add";
export const API_PROMOTIONS_GET_URL = "/admin/promotions/view";
export const API_PROMOTIONS_UPDATE_URL = "/admin/promotions/update";
export const API_PROMOTIONS_DELETE_URL = "/admin/promotions/remove";
// Store's Items
export const API_GET_STORES_ITEMS = "/store/getAllStoreItem/";
export const API_GET_PRODUCT_ITEMS = "/admin/allProducts";
export const API_GET_PRODUCT_LIST = "/admin/getMasterData";

export const API_GET_STORES_ITEM_BY_ID = "/store/getItemById/";
export const API_ADD_STORES_ITEM = "/store/addStoreItem/";
export const API_EDIT_STORES_ITEM = "/store/updateStoreItem";
export const API_DELETE_STORES_ITEM = "/store/deleteStoreItem";
export const API_UPDATE_STORE_ITEM_STATUS = "/store/updateItemStatus";

// earning
export const API_GET_EARNING_LIST = "/admin/adminEarningByDates";
export const API_GET_REPORT_BY_ID = "/admin/adminEarningStoreView/"

// Reports
export const API_GET_REPORT_ITEMS = "/store/soldItemForAdmin";
export const API_GET_ACCOUNTS_LIST ="/store/payOuts"

export const API_STORE_ORDERS_LIST = "/store/storeSoldItemForAdmin";
export const API_TOP_SOLD_ITEM = "/store/soldItemForAdmin";
// Product items
export const API_ADD_PRODUCT = "/admin/addMasterData";
export const API_EDIT_PRODUCT = "/admin/editMasterDataByAdmin";
export const API_DELETE_PRODUCT = "/admin/deleteProductsByAdmin";
export const API_GET_PRODUCT_ITEM_BY_ID = "/admin/getMasterdata/";
export const API_PRODUCT_STATUS_CHANGE = "/admin/approvedItem";
export const API_GET_UNIT_SIZE = "/restaurant/getSizeUnit";


// Drivers
export const API_GET_DRIVER_URL = "/admin/driver/driversList/";
export const API_GET_DRIVER = "/admin/driver/getDriverById/";
export const API_ADD_DRIVER = "/driver/registerbyadmin/";
export const API_EDIT_DRIVER = "/admin/driver/updateDriver/";
export const API_DELETE_DRIVER = "/admin/driver/deleteDriver/";
export const API_DRIVER_PAYMENTS =
  "/admin/driver/getAllDriverTransactionsById/";
export const API_STATUS_CHANGE_RIDER = "/admin/driver/statusChange/";
export const API_BLOCK_RIDER = "/admin/driver/blockUnblock/";

// Customers URLs
export const API_GET_CUSTOMERS_URL = "/admin/getUsersWithFilter/";
export const API_GET_CUSTOMER = "/admin/getUserById/";
export const API_ADD_CUSTOMER = "/admin/addUser/";
export const API_EDIT_CUSTOMER = "/admin/editUser/";
export const API_DELETE_CUSTOMER = "/admin/removeUser/";
export const API_UPLOAD_CUSTOMER = "/user/importCsvList/";

// Brands URLs
export const API_GET_BRANDS_URL = "/main/brand/getBrandListing/";
export const API_GET_BRAND = "/main/brand/getBrandDetailById/";
export const API_ADD_BRAND = "/main/brand/addBrand/";
export const API_EDIT_BRAND = "/main/brand/updateBrand/";
export const API_DELETE_BRAND = "/main/brand/removeBrand/";

// subscription
export const API_GET_SUBSCRIPTION_URL = "/admin/getSubscriptionByAdmin";
export const API_EDIT_SUBSCRIPTION = "/admin/editSubscriptionByAdmin";
export const API_GET_SUBSCRIPTION_BY_ID = "/admin/getSubScription/";



// Varient
export const API_ADD_VARIENT = "/admin/addVarientByAdmin";
export const API_GET_VARIENT_LIST = "/admin/getVarientbyAdminWithFilter";
export const API_GET_VARIENT_BY_ID = "/admin/getVarient/";
export const API_EDIT_VARIENT = "/admin/editVarientByAdmin";

//Admin Setting URLs
export const API_GET_ALL_ADMINS = "/admin/getAllAdminList/";
export const API_EDIT_ADMIN = "/admin/editAdmin/";
export const API_ADD_ADMIN = "/admin/addAdmin/";
export const API_GET_ADMIN = "/admin/getUserById/";

// Content pages
export const API_GET_CONTENT_PAGES_URL = "/admin/contents/";
export const API_EDIT_CONTENT_PAGE = "/admin/contents/update";
export const API_GET_CONTENT_PAGE_BY_ID = "/admin/contents/edit/";

// Faq
export const API_GET_ALL_FAQ = "/admin/getAllFaqs/";
export const API_ADD_FAQ = "/admin/addFaq/";
export const API_EDIT_FAQ = "/admin/editFaq/";
export const API_GET_FAQ = "/admin/getFaqsById/";
export const API_DELETE_FAQ = "/admin/deleteFaq/";

///// Settings

//Mail Templates Url
export const API_GET_MAIL_TEMPLATES = "/adminSetting/getMailTitle/";
export const API_GET_MAIL_TEMPLATE = "/adminSetting/getMailTemplateById/";
export const API_ADD_MAIL_TEMPLATE = "/adminSetting/addMailTemplate/";
export const API_EDIT_MAIL_TEMPLATE = "/adminSetting/editMailTemplate/";

//Sms Templates Url
export const API_GET_SMS_TEMPLATES = "/adminSetting/getSmsTemplate/";
export const API_GET_SMS_TEMPLATE = "/adminSetting/getSmsTemplateById/";
export const API_ADD_SMS_TEMPLATE = "/adminSetting/addSmsTemplate/";
export const API_EDIT_SMS_TEMPLATE = "/adminSetting/editSmsTemplate/";

// Car Types Urls
// export const API_GET_ALL_CAR_TYPES = '/admin/cartypes/';
export const API_GET_ALL_CAR_TYPES = "main/adminCarTypes/getAllCarType";
export const API_GET_CAR_TYPE_BY_ID = "main/adminCarTypes/getCarTypeById/";
export const API_ADD_CAR_TYPE = "main/adminCarTypes/addCarType";
// export const API_GET_CAR_TYPE_BY_ID = '/admin/cartypes/edit/';
// export const API_EDIT_CAR_TYPE = '/admin/updatecartype/';
export const API_EDIT_CAR_TYPE = "main/adminCarTypes/editCarType";
// http://localhost:5004/api/v1/main/adminCarTypes/addCarType(post)
// http://localhost:5004/api/v1/main/adminCarTypes/editCarType(post)
// http://localhost:5004/api/v1/main/adminCarTypes/getAllCarType(post)
// http://localhost:5004/api/v1/main/adminCarTypes/getCarTypeById/_id(id bhej dena param m )(get)
// http://localhost:5004/api/v1/main/adminCarTypes/getCarType(post)
// http://localhost:5004/api/v1/main/adminCarTypes/deletecarType(post)
// pricing
export const API_GET_ALL_PRICING = "/admin/pslist/";
export const API_GET_PRICING_BY_ID = "/admin/psdetails/";
export const API_ADD_PRICING = "/admin/addps/";
export const API_EDIT_PRICING = "/admin/updateps/";
export const API_REMOVE_PRICING = "/admin/removeps/";

// Trips URLsapi/v1/admin/orderdetails/
export const API_GET_ORDERS = "/adminDashboard/allTripsWithFilter/";
export const API_GET_ORDER_BY_ID = "/admin/order/";

// Promo Code URLs
export const API_GET_ALL_PROMOCODE = "/adminSetting/getPromoCodeList/";
export const API_ADD_PROMOCODE = "/adminSetting/addPromoCode/";
export const API_EDIT_PROMOCODE = "/adminSetting/editPromocode/";
export const API_UPDATE_PROMOCODE_STATUS =
  "/adminSetting/updatePromocodeStatus/";
export const API_GET_PROMOCODE = "/adminSetting/getPromocodeDetails/";
export const API_DELETE_PROMOCODE = "/adminSetting/promocodeDelete/";

// Push Notification URLs
export const SEND_PUSH_NOTIFICATION = "admin/sendNotification";

// Settings
export const API_UPDATE_ADMIN_PASSWORD = "/adminSetting/changePassword/";
export const API_GET_BASIC_SETTINGS = "/adminSetting/getBasicsettinginfo";
export const API_UPDATE_BASIC_SETTINGS = "/adminSetting/basicAppSetting";
export const API_UPDATE_NOTIFICATION_SETTINGS =
  "/adminSetting/notificationSettingUpdate/";
export const API_UPDATE_SOCIAL_SETTINGS = "/adminSetting/basicSocialSetting";
export const API_INSTALLATION_SETTINGS = "/adminSetting/getConfigInfo";
export const API_UPDATE_SMS_INSTALLATION_SETTINGS =
  "/adminSetting/twilioUpdate";
export const API_UPDATE_PAYMENT_INSTALLATION_SETTINGS =
  "/adminSetting/PaymentConfigUpdate";
export const API_UPDATE_MAILGUN_INSTALLATION_SETTINGS =
  "/adminSetting/MailGunConfigUpdate";
export const API_UPDATE_ANDROID_INSTALLATION_SETTINGS =
  "/adminSetting/AndroidAppUrlUpdate";
export const API_UPDATE_IOS_INSTALLATION_SETTINGS =
  "/adminSetting/IOSAppURLUpdate";
export const API_GET_MAILTEMPLATE = "/adminSetting/getMailTitle";
export const API_UPDATE_MAILTEMPLATE = "/adminSetting/editMailTemplate";
export const API_GET_SMSTEMPALTE = "/adminSetting/getSmsTemplate";
export const API_UPDATE_SMSTEMPALTE = "/adminSetting/editSmsTemplate";


//report

export const API_GET_REPORT_ITEM_BY_ID = "/store/soldItemForAdmin/";
export const API_GET_STORE_REPORT_ITEM_BY_ID = "/store/storeSoldItemForAdmin/";
